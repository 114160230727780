.collection-name {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 30px;
    line-height: 0px;

    color: white;
    text-align: center;
}

.collection-header {
    text-align: center;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    margin-top: 100px;
    color: white;
    font-size: 60px;
}

@media screen and (max-width:400px){
    .collection-header{
        font-size: 40px;
    }
    .collection-name{
        font-size: 25px;
    }
}