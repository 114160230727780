.team-description {
    font-family: 'Amatic SC', cursive;
    font-size: 35px;
    line-height: 0px;

    color: white;
    text-align: center;
}

.team-section-name {
    font-family: 'Amatic SC', cursive;
    line-height: 0px;
    font-size: 30px;
    color: white;
    text-align: center;

}

.carousel-picture{
    border-radius: 15px;
}

.team-name-text{
    font-size: 25px;
}

