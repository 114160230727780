.header-links{
    color: #fff;
    font-size: 20px;
    margin-top: 7.5px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 85%;

}

.header-icons{
    font-size: calc(1.8vw + 10px);
}

@media screen and (max-width:700px){
    .header-links{
        margin-right: 20px;
        font-size: 15px;

    }
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-dropdown {
    height: 12vh;
    position: relative;
    border: none;
    color: white;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

}

.nav-dropdown:hover {
    text-align: center;
}

.nav-dropdown>ul {
    margin-top: 0px;
    display: none;
    list-style-type: none;
}

.nav-dropdown:hover>ul {
    display: inline-block;
    background-color: #0c0b0be0;
    padding: 0px 25px 15px 25px;
}

.nav-dropdown:hover>ul>li {
    margin-top: 20px;
}

.nav-dropdown:hover>ul>li:hover {
    margin-top: 25px;
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}