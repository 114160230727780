.mint-button {
    font-family: 'Amatic SC', cursive;
    font-weight: bolder;
    color:white;
    background-color: blue;
    border: blue;
    border-radius: 10px;
    font-size: 2.5vw;
    padding: 10px 50px 10px 50px;
    cursor: pointer;
}

