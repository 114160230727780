@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap" rel="stylesheet');
@import url('https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css');
@import url('https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css');
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300');


html,body{
  background-color: #161617;
}
img{
  max-width: 100%;
  height: auto;
}