

.connect-wallet-button {    
    color: white;
    background-color: #0000FF;
    border-color: #0000FF;
    padding: 15px 20px 15px 20px;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
}

@media screen and (max-width:700px){
    .connect-wallet-button{
        padding: 5px 10px 5px 10px;

    }
}




.modal>.content {
    border-radius: 10px;
    background-color: #161617;
    color: white;
    line-height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.card {
    cursor: pointer;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card:hover{
    transition: all 0.3s ease-out;
    background: rgba(0,0,0,.15);
    transition: 250ms;

}











