@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit&display=swap" rel="stylesheet);
@import url(https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css);
@import url(https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css);
@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mint-button {
    font-family: 'Amatic SC', cursive;
    font-weight: bolder;
    color:white;
    background-color: blue;
    border: blue;
    border-radius: 10px;
    font-size: 2.5vw;
    padding: 10px 50px 10px 50px;
    cursor: pointer;
}


.team-description {
    font-family: 'Amatic SC', cursive;
    font-size: 35px;
    line-height: 0px;

    color: white;
    text-align: center;
}

.team-section-name {
    font-family: 'Amatic SC', cursive;
    line-height: 0px;
    font-size: 30px;
    color: white;
    text-align: center;

}

.carousel-picture{
    border-radius: 15px;
}

.team-name-text{
    font-size: 25px;
}


.header-links{
    color: #fff;
    font-size: 20px;
    margin-top: 7.5px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 85%;

}

.header-icons{
    font-size: calc(1.8vw + 10px);
}

@media screen and (max-width:700px){
    .header-links{
        margin-right: 20px;
        font-size: 15px;

    }
}

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nav-dropdown {
    height: 12vh;
    position: relative;
    border: none;
    color: white;
    border-radius: 0px;

}

.nav-dropdown:hover {
    text-align: center;
}

.nav-dropdown>ul {
    margin-top: 0px;
    display: none;
    list-style-type: none;
}

.nav-dropdown:hover>ul {
    display: inline-block;
    background-color: #0c0b0be0;
    padding: 0px 25px 15px 25px;
}

.nav-dropdown:hover>ul>li {
    margin-top: 20px;
}

.nav-dropdown:hover>ul>li:hover {
    margin-top: 25px;
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}
.connect-wallet-container {
}

:root {
    --clear-button-icon-pad: 2px;
    --clear-button-icon-width: 10px;

    --icon-color: black;
}

.rdn {
    position: relative;
}

.rdn-control {
    position: relative;
    overflow: hidden;
    background-color: #6495ED;
    border: 1px solid #6495ED;
    border-radius: 2px;
    box-sizing: border-box;
    color: black;
    cursor: default;
    outline: none;
    padding: 3px 52px 3px 10px;
    transition: all 200ms ease;
}

.rdn-control:hover {
    box-shadow: 0 1px 0 black;
}

.rdn-control-icon {
    display: block;
    height: 0;
    margin-top: -6px;
    position: absolute;
    right: 10px;
    top: 14px;
}

.rdn-control-clear {
    position: absolute;
    top: 8px;
    right: 30px;
    font-size: 0;
    cursor: pointer;
}

.rdn-control-clear-button {
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    position: relative;
    width: calc(10px * 2);
    width: calc(var(--clear-button-icon-width) * 2);
    height: calc(10px * 2);
    height: calc(var(--clear-button-icon-width) * 2);
    cursor: pointer;
}

.rdn-control-clear-button-icon {
    position: absolute;
    left: 3px;
    top: 3px;

    width: 10px;

    width: var(--clear-button-icon-width);
    height: 10px;
    height: var(--clear-button-icon-width);
}

.rdn-control-clear-button-icon:before,
.rdn-control-clear-button-icon:after {
    position: absolute;
    left: calc(10px / 2 * 1px);
    left: calc(var(--clear-button-icon-width) / 2 * 1px);
    content: ' ';
    height: calc(10px + 3px);
    height: calc(var(--clear-button-icon-width) + 3px);
    width: 2px;
    background-color: black;
    background-color: var(--icon-color);
}

.rdn-control-clear-button-icon:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.rdn-control-clear-button-icon:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.rdn-control-arrow-icon {
    border-color: black transparent transparent;
    border-color: var(--icon-color) transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
}

.rdn-control-arrow-icon.is-open {
    border-color: transparent transparent black;
    border-color: transparent transparent var(--icon-color);
    border-width: 0 5px 5px;
}

.rdn-drop,
.rdn-selection {
    background-color: #f7ce4a;
    border: 1px solid #f7ce4a;
    box-shadow: 0 1px 0 black;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
}

.rdn-selection {
    position: relative;
    z-index: auto;
}

.rdn-drop-menu-group-title,
.rdn-selection-menu-group-title {
    padding: 8px 10px;
    color: black;
    font-weight: bold;
    text-transform: capitalize;
}

.rdn-drop-menu-option,
.rdn-drop-menu-group-option,
.rdn-selection-menu-option,
.rdn-selection-menu-group-option {
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.rdn-drop-menu-option.is-disabled-option,
.rdn-drop-menu-group-option.is-disabled-option,
.rdn-selection-menu-option.is-disabled-option,
.rdn-selection-menu-group-option.is-disabled-option {
    cursor: default;
}

.rdn-drop-menu-option:last-child,
.rdn-drop-menu-group-option:last-child,
.rdn-selection-menu-option:last-child,
.rdn-selection-menu-group-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.rdn-drop-menu-option:hover,
.rdn-drop-menu-group-option:hover,
.rdn-selection-menu-option:hover,
.rdn-selection-menu-group-option:hover {
    background-color: #f7ce4a;
    color: black;
}

.rdn-drop-menu-option.is-disabled-option:hover,
.rdn-drop-menu-group-option.is-disabled-option:hover,
.rdn-selection-menu-option.is-disabled-option:hover,
.rdn-selection-menu-group-option.is-disabled-option:hover {
    background-color: #f7ce4a;
    color: black;
}

.rdn-drop-menu-option.is-selected,
.rdn-drop-menu-group-option.is-selected,
.rdn-selection-menu-option.is-selected,
.rdn-selection-menu-group-option.is-selected {
    background-color:#f7ce4a;
    color: black;
}

.rdn-drop-menu-noresults,
.rdn-selection-menu-noresults {
    box-sizing: border-box;
    color: black;
    cursor: default;
    display: block;
    padding: 8px 10px;
}


.connect-wallet-button {    
    color: white;
    background-color: #0000FF;
    border-color: #0000FF;
    padding: 15px 20px 15px 20px;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
}

@media screen and (max-width:700px){
    .connect-wallet-button{
        padding: 5px 10px 5px 10px;

    }
}




.modal>.content {
    border-radius: 10px;
    background-color: #161617;
    color: white;
    line-height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.card {
    cursor: pointer;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card:hover{
    transition: all 0.3s ease-out;
    background: rgba(0,0,0,.15);
    transition: 250ms;

}












html,body{
  background-color: #161617;
}
img{
  max-width: 100%;
  height: auto;
}
.specter-header {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    text-align: center;
    color: black;
    font-size: 100px;
}

.specter-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.specter-artist {
    max-height: auto;
    max-width: 500px;
    margin-right: 100px;
}

.specter-artist-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 90px;
    height: 450px;
    width: 500px;
}

@media screen and (max-width:1100px){
    .specter-container{
        flex-direction: column;
    }
    .specter-artist{
        margin-right: 0px;
        margin-bottom: 50px;
    }
    
}

@media screen and (max-width:650px){
    .specter-artist{
        width: 100%;
        margin-bottom: 0px;
    }
    .specter-artist-info-container{
        height: auto;
        width: auto;
    }
    .specter-header{
        font-size: 11vw;
    }
    .specter-artist-container{
        margin-right: 0px;
    }
    
}

.artist-name{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    text-align: center;
    line-height: 0px;
    font-size: 50px;
    color: black;
}

.artist-details{
    font-family: 'Josefin Sans';

    color: black;
    text-align: center;
    font-size: 18px;
}


.collection-name {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 30px;
    line-height: 0px;

    color: white;
    text-align: center;
}

.collection-header {
    text-align: center;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    margin-top: 100px;
    color: white;
    font-size: 60px;
}

@media screen and (max-width:400px){
    .collection-header{
        font-size: 40px;
    }
    .collection-name{
        font-size: 25px;
    }
}
