.specter-header {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    text-align: center;
    color: black;
    font-size: 100px;
}

.specter-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.specter-artist {
    max-height: auto;
    max-width: 500px;
    margin-right: 100px;
}

.specter-artist-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 90px;
    height: 450px;
    width: 500px;
}

@media screen and (max-width:1100px){
    .specter-container{
        flex-direction: column;
    }
    .specter-artist{
        margin-right: 0px;
        margin-bottom: 50px;
    }
    
}

@media screen and (max-width:650px){
    .specter-artist{
        width: 100%;
        margin-bottom: 0px;
    }
    .specter-artist-info-container{
        height: auto;
        width: auto;
    }
    .specter-header{
        font-size: 11vw;
    }
    .specter-artist-container{
        margin-right: 0px;
    }
    
}

.artist-name{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    text-align: center;
    line-height: 0px;
    font-size: 50px;
    color: black;
}

.artist-details{
    font-family: 'Josefin Sans';

    color: black;
    text-align: center;
    font-size: 18px;
}

